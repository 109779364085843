import testimonialMaria from 'images/testimonials-photo-maria.png';
import testimonialAngel from 'images/testimonials-photo-angel.png';
import testimonialAlicia from 'images/testimonials-photo-alicia.png';

export const TESTIMONIALS_DATA = [
  {
    photo: testimonialMaria,
    name: 'home.testimonials.name_1',
    role: 'home.testimonials.role_1',
    text: 'Solo puedo decir que estoy muy contenta de haber elegido SafeBrok, trato inmejorable explicándote con...',
    rating: 5,
  },
  {
    photo: testimonialAngel,
    name: 'home.testimonials.name_2',
    role: 'home.testimonials.role_2',
    text: 'Una amplia oferta de seguros de ahorro de las principales compañías aseguradoras, con condiciones ventajosas, y con un gest...',
    rating: 5,
  },
  {
    photo: testimonialAlicia,
    name: 'home.testimonials.name_3',
    role: 'home.testimonials.role_3',
    text: 'Necesitaba planificar mi futuro financiero apoyándome en la gestión de personas con experiencia, de forma que...',
    rating: 5,
  },
];
