import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import Box from 'components/Box';
import Button from 'components/Button';

import iconPersonal from 'images/services-icon-personal.svg';
import iconAutonomous from 'images/services-icon-autonomous.svg';
import iconCompanies from 'images/services-icon-companies.svg';
import iconCollective from 'images/services-icon-collective.svg';
import backgroungPattern from 'images/services-background.png';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { breakpoints } from 'theme';

const Container = styled.section`
  text-align: center;

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    margin-top: 40px;

    button {
      width: 100%;
      margin-bottom: 40px;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  background-image: url(${backgroungPattern});
  background-repeat: repeat-x;
  background-position: center;

  @media (max-width: ${breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(2, 156px);
    padding: 0 25px;
  }
`;

const Header = styled.h2`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  color: ${(props) => props.theme.neutralPalette.neutral6};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(22)};
  }
`;

const Subtitle = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(32)};
  line-height: ${rem(46)};
  color: ${(props) => props.theme.brandPalette.dark};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(28)};
    line-height: ${rem(32)};
    margin-top: -28px;
  }
`;

const CardContainer = styled.div`
  text-align: center;

  display: flex;
  flex: 1;
  max-width: 258px;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  padding: 32px 16px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.neutralPalette.neutral3};
  background-color: ${(props) => props.theme.neutralPalette.white};

  @media (max-width: ${breakpoints.tablet}) {
    max-width: 156px;
    max-height: 132px;
    padding: 16px 9px;

    img {
      width: 56px;
      height: auto;
    }
  }
`;

const CardHeading = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  color: ${(props) => props.theme.brandPalette.dark};
  opacity: 0.8;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(12)};
    line-height: ${rem(18)};
  }
`;

const CARD_ITEMS = [
  {
    key: `homepage.services.product_list.personal_insurance`,
    image: iconPersonal,
  },
  {
    key: 'homepage.services.product_list.autonomous_insurance',
    image: iconAutonomous,
  },
  {
    key: 'homepage.services.product_list.business_insurance',
    image: iconCompanies,
  },
  {
    key: 'homepage.services.product_list.associations_collectives_and_businesses',
    image: iconCollective,
  },
];

interface CardProps {
  element: {
    key: string;
    image: any;
  };
}

const Card = ({ element }: CardProps) => {
  const { key, image } = element;
  const { t } = useTranslation();

  return (
    <CardContainer>
      <img src={image} alt="" aria-hidden="true" />
      <CardHeading>{t(key)}</CardHeading>
    </CardContainer>
  );
};

const Services = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Container>
          <Header>{t('homepage.services.product_list.title')}</Header>
          <Subtitle>{t('homepage.services.product_list.description')}</Subtitle>
        </Container>
      </Box>
      <Flex>
        {CARD_ITEMS.map((item) => (
          <Card element={item} key={item.key} />
        ))}
      </Flex>
      <Box>
        <Container>
          <Button href="/services">{t('home.services.cta')}</Button>
        </Container>
      </Box>
    </>
  );
};

export default Services;
