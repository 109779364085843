import React from 'react';
import background from 'images/careers-background.png';
import styled from 'styled-components';
import Box from 'components/Box';
import Button from 'components/Button';
import { rem } from 'polished';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import mobileBackground from 'images/home-careers-mobilebg.png';
import { breakpoints } from 'theme';

const Container = styled.section`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  color: ${(props) => props.theme.neutralPalette.white};

  text-align: center;

  padding: 0 183px;

  @media (max-width: ${breakpoints.tablet}) {
    background-image: url(${mobileBackground});
    background-color: ${(props) => props.theme.brandPalette.dark};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    padding: 0;
    text-align: left;
    background-position: center;
  }
`;

const Heading = styled.h2`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(40)};
  line-height: ${rem(40)};
`;

const Text = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  margin-top: 32px;
  margin-bottom: 56px;

  strong {
    color: ${(props) => props.theme.brandPalette.primary};
    font-weight: ${(props) => props.theme.fontWeights.semibold};
  }
`;

const Careers = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Box>
        <Heading>{t('home.careers.title')}</Heading>
        <Text>
          <Trans i18nKey="home.careers.subtitle" />
        </Text>
        <Button href="/careers" invertedColors>
          {t('home.careers.cta')}
        </Button>
      </Box>
    </Container>
  );
};

export default Careers;
