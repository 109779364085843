import React, { createRef } from 'react';

import TestimonialItem from 'components/TestimonialItem';

import { TESTIMONIALS_DATA } from 'data/testimonials';
import Carousel from 'components/Carousel';

const TestimonialCarousel = () => {
  return (
    <Carousel numberOfVisibleCards={3} numberOfVisibleCardsMobile={1}>
      {TESTIMONIALS_DATA.map((item) => (
        <TestimonialItem ref={createRef()} element={item} key={Math.random()} />
      ))}
    </Carousel>
  );
};

export default TestimonialCarousel;
