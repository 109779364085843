const slugify = (string) => {
  return string
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

module.exports = slugify;

/*
the reason this function uses the "module.exports" syntax is so that it can also be used in gatsby-node.js.
inside tsx/jsx files, it can still be used with the regular "import" syntax.
*/
