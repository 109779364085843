import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import Carousel from 'components/TestimonialCarousel';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { breakpoints } from 'theme';

const Container = styled.section`
  text-align: center;
  padding-top: 80px;

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    padding: 80px 23px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: unset;
  }
`;

const Header = styled.h2`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  color: ${(props) => props.theme.neutralPalette.neutral6};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(22)};
  }
`;

const Subtitle = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(32)};
  line-height: ${rem(46)};
  color: ${(props) => props.theme.brandPalette.dark};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(28)};
    line-height: ${rem(32)};
  }
`;

const Testimonials = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>{t('home.testimonials.title')}</Header>
      <Subtitle>{t('home.testimonials.subtitle')}</Subtitle>
      <Flex>
        <Carousel />
      </Flex>
    </Container>
  );
};

export default Testimonials;
