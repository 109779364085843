import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { NAVIGATION_ITEMS } from 'utils/constants';
import { breakpoints } from 'theme';

const NavigationList = styled.ul`
  list-style: none;
  display: flex;
`;

const NavigationItem = styled.li`
  text-transform: uppercase;
  padding: 24px;

  background-color: transparent;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};

  transition: all 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.neutralPalette.white};
    color: ${(props) => props.theme.brandPalette.primary};
  }
`;

const Nav = styled.nav`
  display: block;

  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: black;
    display: flex;
    justify-content: center;
    z-index: 10;
  }

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

const navItemActiveStyles = {
  backgroundColor: '#ffffff',
  color: '#02C4B2',
};

interface Props {
  scrolling: boolean;
}

const Navigation = ({ scrolling }: Props) => {
  const { language, t } = useI18next();

  return (
    <Nav>
      <NavigationList className={scrolling ? 'sticky' : ''}>
        {NAVIGATION_ITEMS.map(({ href, key }) => {
          if (language === 'en' && href === '/careers') return;

          return (
            <Link activeStyle={navItemActiveStyles} to={href} key={key}>
              <NavigationItem>{t(key)}</NavigationItem>
            </Link>
          );
        })}
      </NavigationList>
    </Nav>
  );
};

export default Navigation;
