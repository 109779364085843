import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import backgroundPattern from 'images/home-articles-background.png';
import BlogPreview from 'components/BlogPreview';
import fetchPosts from 'services/fetchPosts';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import { PostType } from 'utils/constants/PostType';
import { breakpoints } from 'theme';
import {
  filterByLanguageTag,
  getEnumTagFromLang,
} from 'utils/constants/ContentfulLanguageTags';
import { navigate } from 'gatsby';

const Box = styled.div`
  padding: 80px 180px 80px 180px;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 40px 0 40px 24px;
  }
`;

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.semanticPalette.error.base};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(16)};
  margin: 16px 0;
`;

const Container = styled.section`
  text-align: center;
  width: calc(100% - 24px);
`;

const Grid = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 80px;
  margin-bottom: 56px;

  @media (max-width: ${breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(3, 327px);

    padding-bottom: 4px;
    padding-right: 10px;
    overflow-x: scroll;
    justify-content: flex-start;
  }
`;

const BackgroundWrapper = styled.div`
  background-color: ${(props) => props.theme.brandPalette.light};
  background-image: url(${backgroundPattern});
  background-repeat: no-repeat;
  background-position: bottom right;
`;

const Header = styled.h2`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  color: ${(props) => props.theme.neutralPalette.neutral6};
`;

const Subtitle = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(32)};
  line-height: ${rem(46)};
  color: ${(props) => props.theme.brandPalette.dark};
`;

const Articles = () => {
  const [allPosts, setAllPosts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    let isMounted = true;

    const fetchAllPosts = async () => {
      try {
        setIsLoading(true);
        setErrorMessage(null);

        const res = await fetchPosts({});

        if (!res) {
          throw new Error('Something went wrong, please try again.');
        }

        const filteredPosts = filterByLanguageTag({
          contentfulResponse: res,
          language: i18n.language,
        })
          .filter(
            (item) =>
              item.sys.contentType.sys.id === PostType.BLOG_PREVIEW ||
              item.sys.contentType.sys.id === PostType.PRESS,
          )
          .slice(0, 3);

        setAllPosts(filteredPosts);
      } catch {
        setErrorMessage('Something went wrong, please try again.');
        navigate('/something-went-wrong');
      } finally {
        setIsLoading(false);
      }
    };

    if (isMounted) {
      fetchAllPosts();
    }

    return () => {
      isMounted = false;
    };
  }, [i18n.language]);

  return (
    <BackgroundWrapper>
      <Box>
        <Container>
          <Header>{t('home.blog_section.title')}</Header>
          <Subtitle>{t('home.blog_section.subtitle')}</Subtitle>
          {isLoading && <LoadingSpinner />}
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <Grid>
            {allPosts.map((item: any) => (
              <BlogPreview
                element={item.fields}
                type={item.sys.contentType.sys.id}
                key={item.fields.title}
              />
            ))}
          </Grid>
          <Button href="/insights">{t('home.blog_section.cta')}</Button>
        </Container>
      </Box>
    </BackgroundWrapper>
  );
};

export default Articles;
