import React from 'react';
import background from 'images/contact-background.png';
import backgroundMobile from 'images/contact-background-mobile.png';
import styled from 'styled-components';
import Box from 'components/Box';
import { rem } from 'polished';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Button from 'components/Button';
import { breakpoints } from 'theme';

const Container = styled.section`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  color: ${(props) => props.theme.neutralPalette.white};

  text-align: center;

  padding: 0 183px;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 0;
    background: url(${backgroundMobile}), #02c4b2;
    background-repeat: no-repeat;
    background-position: center bottom;

    button {
      width: 100%;
    }
  }
`;

const Heading = styled.h2`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(40)};
  line-height: ${rem(40)};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(28)};
    line-height: ${rem(32)};
  }
`;

const Text = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  margin-top: 32px;
  margin-bottom: 56px;

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: 16px;
    margin-bottom: 32px;
  }
`;

const Contact = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Box>
        <Heading>{t('home.contact_us.title')}</Heading>
        <Text>{t('home.contact_us.description')}</Text>
        <Button href="/contact" invertedColors>
          {t('home.contact_us.cta_button')}
        </Button>
      </Box>
    </Container>
  );
};

export default Contact;
