import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import styled from 'styled-components';
import { rem } from 'polished';

import NavigationColor from 'utils/constants/NavigationColor';
import Navigation from 'components/Navigation';

import homeHero from 'images/home-hero-background.png';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { breakpoints } from 'theme';
import Button from 'components/Button';

const Container = styled.header`
  background-image: url(${homeHero});
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: ${(props) => props.theme.neutralPalette.white};
`;

const TextHeader = styled.h1`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(48)};
  line-height: ${rem(60)};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(40)};
    line-height: ${rem(45)};
  }
`;

const TextSubtitle = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(20)};
  line-height: ${rem(32)};

  margin-top: 32px;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    margin-top: 48px;
  }
`;

const TextContainer = styled.div`
  padding: 152px 317px;

  button {
    display: none;

    @media (max-width: ${breakpoints.tablet}) {
      display: block;
      width: 100%;
      margin-top: 80px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    padding: 142px 24px 80px 24px;
  }
`;

const Flex = styled.span`
  display: flex;
  justify-content: center;

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

const PageHero = () => {
  const [scrolling, setScrolling] = useState(false);

  const { t } = useTranslation();

  const handleScroll = () => {
    if (window.pageYOffset >= 530) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.addEventListener('scroll', handleScroll);
  });

  return (
    <Container>
      <Header variant={NavigationColor.LIGHT} />
      <TextContainer>
        <TextHeader>{t('home.atf.title')}</TextHeader>
        <TextSubtitle>{t('home.atf.description')}</TextSubtitle>
        <Button href="/contact">{t('home.contact_us.cta_button')}</Button>
      </TextContainer>
      <Flex>
        <Navigation scrolling={scrolling} />
      </Flex>
    </Container>
  );
};

export default PageHero;
