import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import controlArrow from 'images/arrow-button.svg';
import { breakpoints } from 'theme';

const ControlArrowDesktop = styled.button<{
  previous?: boolean;
  disabled?: boolean;
}>`
  user-select: none;
  background-color: transparent;
  border: none;
  z-index: 0;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  transform: ${({ previous }) => (previous ? 'rotate(180deg)' : 'none')};

  img {
    transition: all 150ms ease-in-out;

    &:hover {
      cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
      transform: ${({ disabled }) => (disabled ? 'none' : 'scale(1.1)')};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

const ControlArrowMobile = styled.button<{
  previous?: boolean;
  disabled?: boolean;
}>`
  user-select: none;
  background-color: transparent;
  border: none;
  z-index: 0;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  transform: ${({ previous }) => (previous ? 'rotate(180deg)' : 'none')};

  img {
    transition: all 150ms ease-in-out;

    &:hover {
      cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
      transform: ${({ disabled }) => (disabled ? 'none' : 'scale(1.1)')};
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

const CarouselContainer = styled.div<{
  height: number;
  itemWidth: number;
  itemMargin: number;
  visibleCards: number;
  visibleCardsMobile: number;
}>`
  padding-top: ${(props) => `${props.height}px`};
  position: relative;
  width: ${(props) => `
    ${(props.itemWidth + props.itemMargin * 2) * props.visibleCards}px`};
  margin-top: 80px;
  margin-bottom: 80px;
  z-index: 0;

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: 40px;
    margin-bottom: 0;

    width: ${(props) => `
    ${props.itemWidth * props.visibleCardsMobile}px`};
  }
`;

const CarouselWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
`;

const CarouselSlider = styled.div<{
  currentSlide: number;
  itemWidth: number;
  itemMargin: number;
}>`
  display: flex;
  transform: ${(props) =>
    `translateX(-${
      (props.itemWidth + props.itemMargin) * props.currentSlide
    }px)`};
  transition: transform 0.5s ease-in-out;
  width: 100%;

  @media (max-width: ${breakpoints.tablet}) {
    transform: ${(props) =>
      `translateX(-${props.itemWidth * props.currentSlide}px)`};
  }
`;

const CarouselControls = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  height: ${(props) => `${props.height}px`};
  justify-content: space-between;
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    position: initial;
    justify-content: center;
    gap: 16px;
    height: initial;
    margin-top: 40px;
    margin-bottom: 80px;
  }
`;

interface CarouselProps {
  children: React.ReactNode;
  numberOfVisibleCards: number;
  numberOfVisibleCardsMobile: number;
}

const Carousel = ({
  children,
  numberOfVisibleCards,
  numberOfVisibleCardsMobile,
}: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [carouselHeight, setCarouselHeight] = useState(0);
  const [singleItemWidth, setSingleItemWidth] = useState(0);
  const [singleItemMargin, setSingleItemMargin] = useState(0);
  const [totalNumberOfCards, setTotalNumberOfCards] = useState(0);

  useLayoutEffect(() => {
    const slider = document.querySelector('.carouselSlider');

    setTotalNumberOfCards(children.length);
    setSingleItemWidth(children[0].ref.current.clientWidth);
    setSingleItemMargin(
      parseFloat(
        window
          .getComputedStyle(slider.firstChild)
          .getPropertyValue('margin-right')
          .replace('px', ''),
      ),
    );
  }, []);

  const disablePreviousButton = 0 === currentSlide;
  const disableNextButton =
    totalNumberOfCards - numberOfVisibleCards === currentSlide;

  const disableNextButtonMobile =
    totalNumberOfCards - numberOfVisibleCardsMobile === currentSlide;

  const prevSlide = () => {
    const newCurrentSlide = currentSlide - 1;
    setCurrentSlide(newCurrentSlide);
  };

  const nextSlide = () => {
    const newCurrentSlide =
      totalNumberOfCards - 1 === currentSlide ? 0 : currentSlide + 1;
    setCurrentSlide(newCurrentSlide);
  };

  useLayoutEffect(() => {
    const slider = document.querySelector('.carouselSlider');
    setCarouselHeight(slider!.offsetHeight);
  }, []);

  const DesktopControls = (
    <>
      <ControlArrowDesktop
        previous
        onClick={prevSlide}
        disabled={disablePreviousButton}
      >
        <img src={controlArrow} alt="Previous" />
      </ControlArrowDesktop>
      <ControlArrowDesktop onClick={nextSlide} disabled={disableNextButton}>
        <img src={controlArrow} alt="Next" />
      </ControlArrowDesktop>
    </>
  );

  const MobileControls = (
    <>
      <ControlArrowMobile
        previous
        onClick={prevSlide}
        disabled={disablePreviousButton}
      >
        <img src={controlArrow} alt="Previous" />
      </ControlArrowMobile>
      <ControlArrowMobile
        onClick={nextSlide}
        disabled={disableNextButtonMobile}
      >
        <img src={controlArrow} alt="Next" />
      </ControlArrowMobile>
    </>
  );

  return (
    <CarouselContainer
      height={carouselHeight}
      itemWidth={singleItemWidth}
      itemMargin={singleItemMargin}
      visibleCards={numberOfVisibleCards}
      visibleCardsMobile={numberOfVisibleCardsMobile}
    >
      <CarouselWrapper>
        <CarouselSlider
          itemWidth={singleItemWidth}
          itemMargin={singleItemMargin}
          className="carouselSlider"
          currentSlide={currentSlide}
        >
          {children}
        </CarouselSlider>
      </CarouselWrapper>
      <CarouselControls height={carouselHeight}>
        {DesktopControls}
        {MobileControls}
      </CarouselControls>
    </CarouselContainer>
  );
};

export default Carousel;
