import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import formatDate from 'utils/formatDate';
import { PostType } from 'utils/constants/PostType';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import slugify from 'utils/slugify';

const CardContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.theme.neutralPalette.white};
  width: 349px;

  box-shadow: rgb(46 41 51 / 8%) 0px 1px 2px, rgb(71 63 79 / 8%) 0px 2px 4px;
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  width: 327px;
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 162px;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const FlexColumn = styled.div`
  text-align: start;
  padding: 24px;

  min-height: 192px;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const ArticleType = styled.h4`
  text-transform: uppercase;
  color: ${(props) => props.theme.neutralPalette.neutral6};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
`;

const ArticleDate = styled.h4`
  color: ${(props) => props.theme.neutralPalette.neutral6};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
`;

const ArticleTitle = styled.h5`
  margin-bottom: 24px;
  margin-top: 8px;

  color: ${(props) => props.theme.brandPalette.dark};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(20)};
  line-height: ${rem(32)};

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const ArticleAuthor = styled.p`
  color: ${(props) => props.theme.neutralPalette.neutral7};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(14)};
  line-height: ${rem(21)};
`;

const ArticleLink = styled.span`
  color: ${(props) => props.theme.brandPalette.primary};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};

  transition: all 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.brandPalette.secondary};
  }
`;

interface BlogPreviewProps {
  type?: PostType.BLOG_PREVIEW | PostType.PRESS;
  datePosted?: string;
  element: {
    author?: string;
    imageUrl: string;
    title: string;
    newsPublisher?: string;
    pressUrl?: string;
    content?: {
      fields: {
        title: string;
      };
    };
  };
}

const standardizePostType = (post: string) => {
  switch (post) {
    case PostType.BLOG_PREVIEW:
      return 'Artículo';
    case PostType.PRESS:
      return 'Prensa';
    default:
      return 'Artículo';
  }
};

const BlogPreview = ({ type, datePosted, element }: BlogPreviewProps) => {
  const { imageUrl, title, author, newsPublisher, pressUrl, content } = element;

  const postSlug = slugify(content?.fields.title ?? '');

  const { t } = useI18next();

  const redirectLink =
    type === PostType.PRESS ? (
      <a target="_blank" href={pressUrl}>
        {t('home.blog_section.read_more')}
      </a>
    ) : (
      <Link to={`/blog/${postSlug}`}>{t('home.blog_section.read_more')}</Link>
    );

  return (
    <CardContainer>
      <ThumbnailWrapper>
        <Thumbnail src={imageUrl} alt="" aria-hidden="true" />
      </ThumbnailWrapper>
      <FlexColumn>
        {type && <ArticleType>{standardizePostType(type)}</ArticleType>}
        {datePosted && <ArticleDate>{formatDate(datePosted)}</ArticleDate>}
        <ArticleTitle>{title}</ArticleTitle>
        <FlexRow>
          <ArticleAuthor>{author || newsPublisher}</ArticleAuthor>
          <ArticleLink>{redirectLink}</ArticleLink>
        </FlexRow>
      </FlexColumn>
    </CardContainer>
  );
};

export default BlogPreview;
