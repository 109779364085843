import React from 'react';
import Box from 'components/Box';
import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from 'theme';

import imgAnalysis from 'images/home-img-analysis.png';
import imgCompany from 'images/home-img-company.png';
import imgCoverage from 'images/home-img-coverage.png';

import imgAnalysisMobile from 'images/home-img-analysis-mobile.png';
import imgCompanyMobile from 'images/home-img-company-mobile.png';
import imgCoverageMobile from 'images/home-img-coverage-mobile.png';

import { useTranslation } from 'gatsby-plugin-react-i18next';

const Container = styled.section`
  text-align: center;

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-top: 80px;
  max-width: 1440px;

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const Header = styled.h2`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.fontWeights.light};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  color: ${(props) => props.theme.neutralPalette.neutral6};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(32)};
  }
`;

const Subtitle = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(32)};
  line-height: ${rem(20)};
  margin-top: 20px;
  color: ${(props) => props.theme.brandPalette.dark};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(28)};
    line-height: ${rem(32)};
    margin-top: 5px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 96px;
  text-align: start;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    gap: 32px;
  }

  :nth-of-type(even) {
    flex-direction: row-reverse;

    @media (max-width: ${breakpoints.tablet}) {
      flex-direction: column;
    }
  }
`;

const CardImageDesktop = styled.img`
  display: block;
  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

const CardImageMobile = styled.img`
  display: none;
  @media (max-width: ${breakpoints.tablet}) {
    display: block;
  }
`;

const CardHeading = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(28)};
  line-height: ${rem(40)};
  color: ${(props) => props.theme.brandPalette.dark};
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(24)};
    line-height: ${rem(27)};
  }
`;

const CardText = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: ${(props) => props.theme.brandPalette.dark};
  opacity: 0.8;
  font-size: ${rem(20)};
  line-height: ${rem(32)};
`;

const CARD_ITEMS = [
  {
    title: `home.about_us.selling_points.análises_y_valoración`,
    text: 'home.about_us.selling_points.title.59',
    imageDesktop: imgAnalysis,
    imageMobile: imgAnalysisMobile,
  },
  {
    title: 'home.about_us.selling_points.coberturas_idóneas',
    text: 'home.about_us.selling_points.title',
    imageDesktop: imgCoverage,
    imageMobile: imgCoverageMobile,
  },
  {
    title: 'home.about_us.selling_points.una_misma_compañía',
    text: 'home.about_us.selling_points.title.32',
    imageDesktop: imgCompany,
    imageMobile: imgCompanyMobile,
  },
];

interface CardProps {
  element: {
    title: string;
    text: string;
    imageDesktop: any;
    imageMobile: any;
  };
}

const Card = ({ element }: CardProps) => {
  const { title, text, imageDesktop, imageMobile } = element;

  const { t } = useTranslation();

  return (
    <CardContainer>
      <CardImageDesktop src={imageDesktop} alt="" aria-hidden="true" />
      <CardImageMobile src={imageMobile} alt="" aria-hidden="true" />
      <div>
        <CardHeading>{t(title)}</CardHeading>
        <CardText>{t(text)}</CardText>
      </div>
    </CardContainer>
  );
};

const About = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Container>
        <Header>{t('home.about_us.title')}</Header>
        <Subtitle>{t('home.about_us.subtitle')}</Subtitle>
        <Flex>
          {CARD_ITEMS.map((item) => (
            <Card element={item} key={item.title} />
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default About;
