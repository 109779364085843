const formatDate = (date: string | Date) => {
  return new Date(date)
    .toISOString()
    .replace(/T.*/, '')
    .split('-')
    .reverse()
    .join('-');
};

/*
returns date in the following format: DD-MM-YYYY (numericals only)
*/

export default formatDate;
