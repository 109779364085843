import React from 'react';
import type { HeadFC } from 'gatsby';

import About from './components/About';
import Contact from './components/Contact';
import Services from './components/Services';
import Articles from './components/Articles';
import Testimonials from './components/Testimonials';
import Careers from './components/Careers';
import PageHero from 'components/PageHero';
import { useI18next } from 'gatsby-plugin-react-i18next';

const Home = () => {
  const { language } = useI18next();

  return (
    <>
      <PageHero />
      <About />
      <Contact />
      <Services />
      <Articles />
      <Testimonials />
      {language !== 'en' && <Careers />}
    </>
  );
};

export default Home;

export const Head: HeadFC = () => <title>Home</title>;
