import { Entry } from 'contentful';

enum LanguageTags {
  PT = 'languagePortuguese',
  ES = 'languageSpanish',
  IT = 'languageItalian',
  EN = 'languageEnglish',
}

export const getEnumTagFromLang = (lang: string) => {
  switch (lang.toLowerCase()) {
    case 'es':
      return LanguageTags.ES;
    case 'it':
      return LanguageTags.IT;
    case 'pt':
      return LanguageTags.PT;
    case 'en':
      return LanguageTags.EN;
    default:
      return LanguageTags.ES;
  }
};

type FilterFunctionParams = {
  contentfulResponse: Entry<unknown>[];
  language: string;
};

export function filterByLanguageTag({
  contentfulResponse,
  language,
}: FilterFunctionParams) {
  return contentfulResponse.filter(
    (item) =>
      item.metadata.tags.filter(
        (tag) => tag.sys.id === getEnumTagFromLang(language),
      )[0],
  );
}
