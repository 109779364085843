import React from 'react';
import Home from 'pageComponents/Home';
import { graphql, HeadProps } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const HomePage = () => <Home />;

export default HomePage;

export function Head(props: HeadProps) {
  const { t } = useTranslation();
  return <title>{t('home.atf.navigation_tabs.home')} | Safebrok</title>;
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
