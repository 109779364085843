import React from 'react';
import styled from 'styled-components';

import ratingStar from 'images/rating-star.svg';
import { rem } from 'polished';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { breakpoints } from 'theme';

const Container = styled.div`
  padding: 40px;
  flex: 1;
  min-width: 350px;
  margin-right: 40px;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 0;
    margin-right: 0;
  }
`;

const Flex = styled.span`
  display: flex;
  gap: 8px;
  justify-content: center;

  @media (max-width: ${breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;

const Photo = styled.img`
  width: 122px;
  height: 122px;
  border-radius: 50%;
`;

const UserName = styled.h4`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  color: ${(props) => props.theme.brandPalette.dark};
  margin-top: 32px;
`;

const UserRole = styled.h5`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  color: ${(props) => props.theme.neutralPalette.neutral6};
`;

const TestimonialText = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  color: ${(props) => props.theme.brandPalette.dark};
  opacity: 0.8;
  margin: 24px 0;

  @media (min-width: ${breakpoints.tablet}) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

const ArticleLink = styled.span`
  color: ${(props) => props.theme.brandPalette.primary};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};

  transition: all 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.brandPalette.secondary};
  }
`;

interface TestimonialProps {
  element: {
    photo: any;
    name: string;
    role: string;
    text: string;
    rating: number;
  };
}

const TestimonialItem = React.forwardRef(({ element }, ref) => {
  const { photo, name, role, text, rating } = element;
  const { t } = useTranslation();

  return (
    <Container ref={ref as React.RefObject<HTMLDivElement>}>
      <Photo src={photo} alt="Client photo" />
      <UserName>{t(name)}</UserName>
      <UserRole>{t(role)}</UserRole>
      <TestimonialText>
        {text}
        <ArticleLink>Ver más</ArticleLink>
      </TestimonialText>
      <Flex>
        {Array.from({ length: rating }, () => (
          <img src={ratingStar} key={Math.random()} />
        ))}
      </Flex>
    </Container>
  );
});

export default TestimonialItem;
